import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import "../../style/home/propiedades.css";
import { Grid } from "@mui/material";


/* imagenes */
import imagen from "../../img/home/propiedades/propiedad1.png";
import imagen1 from "../../img/home/propiedades/propiedad2.png";
import imagen2 from "../../img/home/propiedades/propiedad3.png";

export const Propiedadeshome = () => {
    useEffect(() => {
        AOS.init({
          // Configura las opciones aquí
          duration: 2000,
        });
      }, []);
  return (
    <section  data-aos="fade-down">
      <section className="titulo-propiedad">
        <h1>PROPIEDADES</h1>
        <hr />
      </section>
      <section>
        <Grid container>
        <Grid item xs={12} sm={6} md={4}>
    <div className="contiene-todo-homepropiedad">
        <div className="contiene-info-principal">
            <div className="contiene-tipo">
                <h1>venta</h1>
            </div>
        </div>
        <div className="contiene-imagen-propiedad">
            <img className="imagen-propiedad-home" src={imagen} alt="g3 propiedades" />
           
        </div>
        <div className="contiene-lineas"></div>
        <div className="contiene-fondo"></div>
        <div className="contieneinfoespecifico">
            <div className="info-propiedad">
                <h1>4</h1>
                <span>dormitorios</span>
            </div>
            <div className="separador"></div>
            <div className="info-propiedad">
                <h1>2</h1>
                <span>baños</span>
            </div>
            <div className="separador"></div>
            <div className="info-propiedad">
                <h1>130</h1>
                <span>mts2</span>
            </div>
        </div>
        <div className="superposicionpropiedades">
             <a href='/propiedad/id'>
            <button className="boton-propiedad"> <h1>Ver más</h1></button></a></div>
    
    </div>
</Grid>
<Grid item  xs={12} sm={6} md={4}>
    <div className="contiene-todo-homepropiedad">
        <div className="contiene-info-principal">
            <div className="contiene-tipo">
                <h1>venta</h1>
            </div>
        </div>
        <div className="contiene-imagen-propiedad">
            <img className="imagen-propiedad-home" src={imagen1} alt="g3 propiedades" />
            
        </div>
        <div className="contiene-lineas"></div>
        <div className="contiene-fondo"></div>
        <div className="contieneinfoespecifico">
            <div className="info-propiedad">
                <h1>4</h1>
                <span>dormitorios</span>
            </div>
            <div className="separador"></div>
            <div className="info-propiedad">
                <h1>2</h1>
                <span>baños</span>
            </div>
            <div className="separador"></div>
            <div className="info-propiedad">
                <h1>130</h1>
                <span>mts2</span>
            </div>
        </div>
        <div className="superposicionpropiedades">
        <a href='/propiedad/id'>
            <button className="boton-propiedad"> <h1>Ver más</h1></button></a> </div>
   
    </div>
</Grid>
<Grid item  xs={12} sm={6} md={4}>
    
    <div className="contiene-todo-homepropiedad">
        <div className="contiene-info-principal">
            <div className="contiene-tipo">
                <h1>venta</h1>
            </div>
        </div>
        <div className="contiene-imagen-propiedad">
            <img className="imagen-propiedad-home" src={imagen2} alt="g3 propiedades" />
            
        </div>
        <div className="contiene-lineas"></div>
        <div className="contiene-fondo"></div>
        <div className="contieneinfoespecifico">
            <div className="info-propiedad">
                <h1>4</h1>
                <span>dormitorios</span>
            </div>
            <div className="separador"></div>
            <div className="info-propiedad">
                <h1>2</h1>
                <span>baños</span>
            </div>
            <div className="separador"></div>
            <div className="info-propiedad">
                <h1>130</h1>
                <span>mts2</span>
            </div>
        </div>
        <div className="superposicionpropiedades">
            <a href='/propiedad/id'>
            <button className="boton-propiedad"> <h1>Ver más</h1></button></a> </div>
    </div>
</Grid>
        </Grid>
        <section className="boton-mas-propiedades">
            <a href='/propiedades'>
<button  className="boton-ver-mas"> PROPIEDADES</button></a>
        </section>
      </section>
    </section>
  );
};
