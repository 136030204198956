import { Grid } from "@mui/material";
import React from "react";

/* imagenes */
import dor from "../../img/prop-id/dormitorio.png"
import baño from "../../img/prop-id/baños.png"
import construcion from "../../img/prop-id/metros cuadrados.png"
import estacionamiento from "../../img/prop-id/estacionamiento.png"


export const Tituloprop = () => {
    return(
        <>
<Grid container className="contiene-tituloprop"> 

<Grid item xs={12} sm={3} className="tituloprop-info"> 
<div className="titulo-prop-select">
<div className="linea-infoprop "></div>
<div className="infotitulo-prop">
    <h1 className=" nombre-prop">CASA OSORNO CENTRO</h1>
    <h1 className=" uf-prop">     1.400 UF</h1>
</div>
    
    </div> </Grid>
<Grid item xs={12} sm={8} className="titulinfoprop"> 

<Grid container >
    <Grid item xs={3} sm={6} md={3}>
        <div className="info-prop-icono">
            <div className="imagenes-icono-prop">
                <img src={dor} alt="g3 propiedades"/>
                <h1 className="cantidad-prop"> 4 </h1>
            </div>

            <div className="titulo-icono-prop">DORMITORIOS</div>
        </div>
    </Grid>
    <Grid item xs={3} sm={6} md={3}>
        <div className="info-prop-icono">
            <div className="imagenes-icono-prop">
                <img src={baño} alt="g3 propiedades"/>
                <h1 className="cantidad-prop"> 2 </h1>
            </div>

            <div className="titulo-icono-prop">BAÑOS</div>
        </div>
    </Grid>
    <Grid item xs={3} sm={6} md={3}>
        <div className="info-prop-icono">
            <div className="imagenes-icono-prop">
                <img src={construcion} alt="g3 propiedades"/>
                <h1 className="cantidad-prop"> 120  m&sup2;</h1>
            </div>

            <div className="titulo-icono-prop">CONSTRUIDO</div>
        </div>
    </Grid>
    <Grid item xs={3} sm={6} md={3}>
        <div className="info-prop-icono">
            <div className="imagenes-icono-prop">
                <img src={estacionamiento} alt="g3 propiedades"/>
                <h1 className="cantidad-prop"> 2 </h1>
            </div>

            <div className="titulo-icono-prop">ESTACIONAMIENTO</div>
        </div>
    </Grid>
</Grid>

 </Grid>

</Grid>
        
        </>
    )
}