import React from "react";
import { Portadacontacto } from "../componentes/contacto/portada-contacto";
import { Contactoinformacion } from "../componentes/contacto/info-contacto";

export const Contacto = () =>{
    return(
        <>
       <Portadacontacto/>
       <Contactoinformacion/>
        
        </>
    )
}