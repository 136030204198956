import React from "react";
import { Portadahome } from "../componentes/home/portada";
import { Servicio } from "../componentes/home/servicios";
import { Propiedadeshome } from "../componentes/home/propiedades";
import { Categoria } from "../componentes/home/categoria";


export const Home = () => {
    return(
        <>
        <Portadahome/>
        <Servicio/>
        <Propiedadeshome/>
        <Categoria/>
        
        </>
    )
}