import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ub from "../../img/propiedades/ubicacion.png"


/* iconos */
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsWhatsapp } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";

const names = [
  "Compraventa de propiedades",
  "Arrendamiento",
  "Tasación de Propiedades",
  "Estudio de Títulos",
  "Asesoría Legal",
  "Gestión Crediticia",
];




export const Caracteristicasprop = () => {
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <section className="caracteristicas-final-prop">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <section className="info-carac">
              <div className="titulo-info-carac">
                <h1> CARACTERISTICAS DE LA PROPIEDAD</h1>
                <span>
                  {" "}
                  Venta de casa en Población Matthei, con excelente ubicación.
                  Sector residencial y comercial, para disfrutar la vida en
                  familia, cercano a la plaza de Osorno, Clínica Alemana,
                  locomoción y servicios.{" "}
                </span>
              </div>
              <div className="tabla-container">
                <table className="tabla">
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        <strong className="marcado">disponibilidad</strong>
                      </th>
                      <th>:</th>
                      <th>venta</th>
                      <th>
                        <strong className="marcado">tipo </strong>
                      </th>
                      <th>:</th>
                      <th>casa</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <strong className="marcado"> región</strong>
                      </td>
                      <td>:</td>
                      <td>Los Lagos</td>
                      <td>
                        <strong className="marcado">comuna</strong>
                      </td>
                      <td>:</td>
                      <td>osorno</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <strong className="marcado">construido </strong>
                      </td>
                      <td>:</td>
                      <td>
                        120 <span className="metros">m&sup2;</span>{" "}
                      </td>
                      <td>
                        <strong className="marcado">terreno </strong>
                      </td>
                      <td>:</td>
                      <td>
                        320 <span className="metros">m&sup2;</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section className="ubicacion">
              <div className="titulo-ubicacion">
                <img src={ub} alt="g3 propiedades" />
                <h1>UBICACIÓN</h1>
              </div>
              <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d196082.65940817335!2d-73.33073144688935!3d-39.83409095861541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9615e94f20de4501%3A0x89b7301051537813!2sValdivia%2C%20Los%20R%C3%ADos!5e0!3m2!1ses-419!2scl!4v1709778470761!5m2!1ses-419!2scl"
  width="100%"
  height="350"
  style={{ border: "0" }}
  allowfullscreen=""
  loading="lazy"
  referrerpolicy="no-referrer-when-downgrade"
  title="Mapa de Valdivia, Los Ríos"
></iframe>
            </section>
          </Grid>
          <Grid item xs={12} sm={6}>
            <section className="contacto-prop">
              <div className="card-prop">
                <div className="portada-card-prop">
                  <h1>contactanos</h1>
                  <span>
                    Te interesa esta propiedad escribenos o contactanos para
                    saber más de la propiedad.
                  </span>
                </div>
                <div className="informacion-contactprop">
                  <div className="propcontacto">
                    <div className="propcontienecontacto">
                      <FaPhoneAlt />{" "}
                      <a href="tel:+56993273749"> +56 9 9327 3749</a>
                    </div>

                    <div className="propcontienecontacto">
                      <HiOutlineMail />
                      <a href="mailto:contacto@g3propiedades.cl">
                        contacto@g3propiedades.cl
                      </a>
                    </div>
                  </div>
                  <div className="propredessociales">
                    <div className="propredessociales">
                      <Link className="propsocialredes propcontaineruno" to="#">
                        <BsWhatsapp />
                      </Link>
                      <Link
                        className="propsocialredes  propcontainerdos"
                        to="#"
                      >
                        <GrInstagram />
                      </Link>
                      <Link class="propsocialredes  propcontainertres" to="#">
                        <FaFacebookF />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="formulario-prop">
              <form className="formprop">
                <div className="inputprop">
                  <TextField
                    id="outlined-basic"
                    label="Asunto"
                    variant="outlined"
                    fullWidth="100"
                  />
                </div>
                <div className="inputprop">
                  <TextField
                    id="outlined-basic"
                    label="Nombre"
                    variant="outlined"
                    fullWidth="100"
                  />
                </div>
                <div className="inputprop">
                  <TextField
                    className="inputform"
                    label="Teléfono"
                    variant="outlined"
                    type="tel"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+569</InputAdornment>
                      ),
                    }}
                    fullWidth
                    name="telefono"
                  />
                </div>
                <div className="inputprop">
                  <TextField
                    id="outlined-basic"
                    label="Correo"
                    variant="outlined"
                    fullWidth="100"
                  />
                </div>
                <div className="inputprop">
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Servicio
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput label="seleccionar" />}
                      renderValue={(selected) => selected.join(", ")}
                      className="select"
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={personName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="inputprop">
                  <TextField
                    id="outlined-basic"
                    label="Mensaje"
                    name="mensajes"
                    fullWidth
                    variant="outlined"
                  />
                </div>
                
                <div  className="inputprop">
                  {" "}
                  <button className="enviarpropform"> ENVIAR</button>
                </div>
              </form>
            </section>
          </Grid>
        </Grid>
      </section>
    </>
  );
};
