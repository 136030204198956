import React from "react";
import { Portadapropiedades } from "../componentes/propiedades/portada";
import { Filtrar } from "../componentes/propiedades/filtrado";
import { Formulariopropiedades } from "../componentes/propiedades/formulario";

export const Propiedades = () =>{
    return(
        <>
        <Portadapropiedades/>
        <Filtrar/>
        <Formulariopropiedades/>
        </>
    )
}