import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import "../../style/contacto/contacto.css"

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

/* imagenes */
import fondo from "../../img/contacto/fondos.png"

/* iconos */
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsWhatsapp } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";

const names = [
    'Visita Inmobiliaria',
  ];



export const Contactoinformacion = () => {
    const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
    return(
        <section style={{ backgroundImage: `url(${fondo})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
        <Grid container className="formulariocontacto">
<Grid xs ={12} sm={6}>
<div className="formulario-contacto-general">
    <section className="titulo-cotacto">
        <h1>CONTÁCTANOS</h1>
        <span>Si tienes alguna duda o consulta acerca de nuestros precios y/o servicios, envíanos un mensaje, nuestro equipo estará listo para responderte a la brevedad. </span>

    </section>

<div>
              <form>
                <div className="formulario-input">
                  <TextField
                    className="inputform"
                    fullWidth
                    label="Nombre"
                    variant="filled"
                  />
                </div>
                <div className="formulario-input">
                  <TextField
                    className="inputform"
                    fullWidth
                    label="Correo"
                    variant="filled"
                  />
                </div>
                <div className="formulario-input">
                  <TextField
                    className="inputform"
                    label="Teléfono"
                    variant="filled"
                    type="tel"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+569</InputAdornment>
                      ),
                    }}
                    fullWidth
                    name="telefono"
                  />
                </div>
                <div className="formulario-input">
      <FormControl style={{ width: '100%' }} >
        <InputLabel id="demo-multiple-checkbox-label">Servicio</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="seleccionar" />}
          renderValue={(selected) => selected.join(', ')}
          className="select"
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
                <div className="formulario-input">
                  <TextField
                    className="inputform"
                    label="Mensaje"
                    variant="filled"
                    name="mensajes"
                    fullWidth
                  />
                </div>

                <div className="formulario-input">
                  {" "}
                  <button className="enviarform"> ENVIAR</button>
                </div>
              </form>
            </div>
</div>

</Grid>
<Grid xs ={12} sm={6} className="contiene-contacto-prop-form">
<div className="informacion-contacto-general">
              <div className="contacto">
                <div className="contienecontacto">
                  <FaPhoneAlt /> <a href="tel:+56993273749"> +56 9 9327 3749</a>
                </div>

                <div className="contienecontacto">
                  <HiOutlineMail />
                  <a href="mailto:contacto@g3propiedades.cl">
                    contacto@g3propiedades.cl
                  </a>
                </div>
              </div>
              <div className="redessociales">
                <div class="redessociales">
                  <Link class="socialredes containeruno"  to="https://api.whatsapp.com/send/?phone=56993273749&type=phone_number&app_absent=0">
                    <BsWhatsapp />
                  </Link>
                  <Link class="socialredes  containerdos" to="https://www.instagram.com/g3propiedadesosorno/">
                    <GrInstagram />
                  </Link>
                  <Link class="socialredes  containertres" to="https://www.facebook.com/profile.php?id=100094015707106">
                    <FaFacebookF />
                  </Link>
                </div>
              </div>
            </div>
</Grid>

        </Grid>
        
        
        </ section>
    )
}