import React from "react";
import { Grid } from "@mui/material";
import "../../style/home/home.css";
/* imagenes */
import portada from "../../img/home/portada.png";

export const Servicioportada = () => {
    return(
        <>
         <>
      <section>
        <Grid container>
       
                                <div className="portada-home-img">
                                    <div className="imagen-portada">
                                        <img
                                            src={portada}
                                            className="imagen-portada-home"
                                            alt="G3 propiedades"// Usamos el ID para describir la imagen
                                        />
                                        <div className="fondo"></div>
                                    </div>
                                    <div className="titulo-portada-home">
                                    <div className="titulo-principal">
                                            <h1>CONOCE NUESTROS SERVICIOS</h1>
                                        </div>
                                    </div>
                                    </div>
                        

   
         
        </Grid>
      </section>
    </>
        </>
    )
}