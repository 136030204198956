import React from "react";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import Logo from "../../img/nosotros/logo.png"


export const Formarren =() =>{
    return(
        <section>
<section className="form-titulo">
    <img src={Logo} alt="propiedades" />
    <span>“Tu Aliado en Corretaje y Gestión Inmobiliaria”</span>
    <span>ARRENDAMIENTO</span>
</section>
<form>
                <div className="formulario-input">
                  <TextField
                    className="inputform"
                    fullWidth
                    label="Nombre"
                    variant="filled"
                  />
                </div>
                <div className="formulario-input">
                  <TextField
                    className="inputform"
                    fullWidth
                    label="Correo"
                    variant="filled"
                  />
                </div>
                <div className="formulario-input">
                  <TextField
                    className="inputform"
                    label="Teléfono"
                    variant="filled"
                    type="tel"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+569</InputAdornment>
                      ),
                    }}
                    fullWidth
                    name="telefono"
                  />
                </div>
                <div className="formulario-input">
                  <TextField
                    className="inputform"
                    label="Mensaje"
                    variant="filled"
                    name="mensajes"
                    fullWidth
                  />
                </div>

                <div>
                  {" "}
                  <button className="enviarform"> ENVIAR</button>
                </div>
              </form>
        
        </section>
    )
}