import React, { useState } from "react";
import { Grid } from "@mui/material";
import "../../style/servicio/servicio.css";
import { Formulariopropiedades } from "../propiedades/formulario";

/* imagenes */
import img1 from "../../img/servicio/prop.png";
import img2 from "../../img/servicio/prop1.png";
import icono from "../../img/servicio/otro.png";
import icono1 from "../../img/servicio/otro1.png";
import icono2 from "../../img/servicio/otro2.png";
import icono3 from "../../img/servicio/otro3.png";

/* import */
import { Formarren } from "../servicios/formarrendar";
import { Formarcom } from "./formcompraventa";
import { IoClose } from "react-icons/io5";

export const Serviciocontenido = () => {
  const [showPopup1, setShowPopup1] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);

  const togglePopup1 = () => {
    setShowPopup1(!showPopup1);
  };

  const togglePopup2 = () => {
    setShowPopup2(!showPopup2);
  };
  return (
    <>
      <Grid container className="contieneninfo-servicio-completo">
        <Grid xs={12} sm={6} className="contieneinfoservicio">
          <div className="info-servicio">
            <div className="informacion-g">
              <div className="informacion-titulo">
                <h1> COMPRAVENTA DE PROPIEDADES</h1>
              </div>
              <div className="informcion-descripcion">
                <span>
                  Explora nuevas oportunidades con nuestro servicio de
                  compraventa inmobiliaria. Ofrecemos expertise en aspectos
                  legales y técnicos para garantizar transacciones seguras y
                  exitosas en un mercado diverso y competitivo. Confía en
                  nosotros para hacer realidad tus sueños inmobiliarios de
                  manera eficiente y confiable
                </span>
              </div>
              <button className="botoncotizar" onClick={togglePopup1}>
                {" "}
                COTIZA CON NOSOTROS
              </button>
            </div>
            <div className="linea-info-g"></div>
          </div>
        </Grid>
        {showPopup1 && (
          <div className="popup-container">
            <div className="popup-content">
              <button className="close-button" onClick={togglePopup1}>
                <IoClose />
              </button>{" "}
              <div className="contiene-formarren">
                <Formarcom />
              </div>
            </div>{" "}
          </div>
        )}
        <Grid xs={12} sm={6}>
          <div className="contieneimagenservicio">
            <img src={img1} alt="g3 propiedades" />
          </div>
        </Grid>
      </Grid>
      <Grid container className="contieneninfo-servicio-completo">
        <Grid xs={12} sm={6} className="imagendos">
          <div className="contieneimagenservicio">
            <img src={img2} alt="g3 propiedades" />
          </div>
        </Grid>
        <Grid xs={12} sm={6} className="contieneinfoservicio">
          <div className="infoservicio">
            <div className="linea-info-g"></div>
            <div className="informaciongdos">
              <div className="informacion-titulo">
                <h1> ARRENDAMIENTO</h1>
              </div>
              <div className="informcion-descripcion">
                <span>
                  Opta por la seguridad y tranquilidad de nuestro servicio,
                  basada en una selección de los arrendatarios. Nos aseguramos
                  que los candidatos cumplan con los requisitos necesarios como
                  información comercial y documentación. Con nuestra experiencia
                  y asesoría, brindamos un servicio eficiente y de confianza
                  para la selección del arrendatario ideal para su propiedad.
                </span>
              </div>
              <button className="botoncotizar" onClick={togglePopup2}>
                {" "}
                COTIZA CON NOSOTROS
              </button>
            </div>
          </div>
        </Grid>
      </Grid>
      {showPopup2 && (
        <div className="popup-container">
          <div className="popup-content">
            <button className="close-button" onClick={togglePopup2}>
              <IoClose />
            </button>{" "}
            <div className="contiene-formarren">
              <Formarren />
            </div>
          </div>{" "}
        </div>
      )}
      <section className="otroservicios">
        <div className="contieneinfootrosservicio">
          <div className="otroservicio-titulo">
            <h1> OTROS SERVICIOS</h1>
            <hr className="sunrayar" />
          </div>
          <div className="otros-servicio-descripcion">
            <span>
              Nuestro servicio de administración inmobiliaria ofrece un enfoque
              integral para maximizar la rentabilidad de su propiedad. Nos
              encargamos de todos los aspectos de la gestión de contratos de
              arrendamiento, asegurando un manejo eficiente y sin preocupaciones
              para los propietarios.{" "}
            </span>
          </div>
        </div>
        <Grid container className="iconos-servicios">
          <Grid xs={12} sm={6} md={3}>
            <div className="icono-otros-servicio">
              <img src={icono} alt="g3 propiedades" />
              <h1>Tasación de </h1>
              <h1>Propiedades</h1>
            </div>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <div className="icono-otros-servicio">
              <img src={icono1} alt="g3 propiedades" />
              <h1>Estudio de</h1>
              <h1> Títulos</h1>
            </div>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <div className="icono-otros-servicio">
              <img src={icono2} alt="g3 propiedades" />
              <h1>Asesoría</h1>
              <h1>Legal</h1>
            </div>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <div className="icono-otros-servicio">
              <img src={icono3} alt="g3 propiedades" />
              <h1>Gestión</h1>
              <h1>Crediticia</h1>
            </div>
          </Grid>
        </Grid>
      </section>
      <section className="formularioservicio">
        <Formulariopropiedades />
      </section>
    </>
  );
};
