import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { EffectFade, Navigation, Autoplay, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import { Grid } from "@mui/material";
import 'swiper/css/pagination';

import "../../style/home/home.css";
import "../../style/propiedad-id/prop.css"

/* imagenes */
import portada from "../../img/home/portada.png";
 import portada2 from "../../img/home/portada2.png" 

export const PortadaProp = () => {

    const Dataportada =[{
        id:"1",
        imagen:portada
    },
    {
        id:"2",
        imagen:portada2
    }
    ]
    return(
        <>
      <section >
        <Grid container className="contiene-portada-prop">
        <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loop={true}
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        pagination={{
            clickable: true // Otras opciones de paginación si las necesitas
          }}
        modules={[EffectFade, Navigation, Autoplay, Pagination]}
        className="swiperprop"
      >
       {Dataportada.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="portada-home-img">
                                    <div className="imagen-portada">
                                        <img
                                            src={item.imagen}
                                            className="imagen-portada-home"
                                            alt={`Portada ${item.id}`} // Usamos el ID para describir la imagen
                                        />
                                        <div className="segundofondo"></div>
                                    </div>
                                   
                                </div>
                            </SwiperSlide>
                        ))}


      </Swiper>
         
        </Grid>
      </section>
        
        </>
    )
}