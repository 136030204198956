import React, {useEffect, useState} from "react";
import { Grid } from "@mui/material";
import "../../style/navbar/navbar.css";
import { Link } from "react-router-dom";

/* menu responsivo */
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
/* iconos */
import { BsWhatsapp } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";




/* imagenes */
import logo from "../../img/navbar/logo.png";


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const scrollToTargetElement = (elementId) => {
  const targetElement = document.getElementById(elementId);
  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export const Navbar = () => {
  const [open, setOpen] = React.useState(false);
  const [menuVisible, setMenuVisible] = useState(true);

  const toggleMenu = () => {
      setMenuVisible(!menuVisible);
  }



  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Drawer
    anchor={anchor}
    open={state[anchor]}
    onClose={toggleDrawer(anchor, false)}
    sx={{
      '& .MuiDrawer-paper': {
        backgroundColor: '#6A6464', // Cambia aquí el color de fondo
      },
    }}
  >
    <Box
    className="contienenavbarmovil"
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,  backgroundColor: '#6A6464', 
      color: '#ffff', }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <div className="contiene-listado">
        <ul className="lista-nav">
            {" "}
            <a href="/propiedades"><li>Propiedades</li></a>
            <a href="/servicios"> <li>Servicios</li></a>
           
            <a href="/nosotros">
            <li>Nosotros</li></a>
            <a href="/contacto">
            <li>Contacto</li> </a>
          </ul>
                            </div>
      </List>
      <Divider />
      <List>
      <div className="card">
  <Link className="socialContainer containerOne" to="https://api.whatsapp.com/send/?phone=56993273749&type=phone_number&app_absent=0">
  <BsWhatsapp />
  </Link>

<Link className="socialContainer containerTwo" to="https://www.instagram.com/g3propiedadesosorno/"> 
<GrInstagram />
</Link>

  <Link className="socialContainer containerThree" to="https://www.facebook.com/profile.php?id=100094015707106"> <FaFacebookF /></Link>
 
</div>   
      </List>
    </Box>
    </Drawer>
  );

     
  useEffect(() => {
    // Agregar un manejador de redimensión para controlar la visibilidad del menú móvil
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setMenuVisible(true);
      } else {
        setMenuVisible(false);
      }
    };

    // Llamar al manejador de redimensión al cargar la página
    handleResize();

    // Agregar el manejador de redimensión al evento de redimensionamiento de la ventana
    window.addEventListener('resize', handleResize);

    // Limpiar el manejador de redimensionamiento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <section className="nav">
        {menuVisible ? (
               <Grid container className="contenedor-navbar menu-mobile">
               <Grid item  xs={10}>
              
                   <div className="contenedor-imgn">
                  
                    <a href="/">
                       <img src={logo} alt="maquinaria" className="imagen-logo-navbar" />
                       </a></div>
               </Grid>
             
                   
               <Grid item  xs={2}>
                   <div className="contenedor-menu">
                   <input type="checkbox"  className='checkbox-navbar'id="checkbox"checked={state.right}
                      onChange={toggleDrawer("right", !state.right)}/>
    <label htmlFor="checkbox" className="toggle">
        <div className="bars" id="bar1"></div>
        <div className="bars" id="bar2"></div>
        <div className="bars" id="bar3"></div>
    </label>
    <SwipeableDrawer
                    className="listado-menu"
                    anchor="right"
                    open={state.right}
                    onClose={toggleDrawer("right", false)}
                    onOpen={toggleDrawer("right", true)}
                  >
                    {list("right")}
                  </SwipeableDrawer>
                  </div>
               </Grid>
            
           </Grid>
             
      ) : (
      <Grid container>
        <Grid item sm={2}>
          <a href="/">
          <img src={logo} className="imagen-logo-navbar" alt="g3 propiedades" /></a>
        </Grid>
        <Grid item sm={6}>
          <ul className="lista-nav">
            {" "}
            <a href="/propiedades"><li>Propiedades</li></a>
            <a href="/servicios"> <li>Servicios</li></a>
           
            <a href="/nosotros">
            <li>Nosotros</li></a>
            <a href="/contacto">
            <li>Contacto</li> </a>
          </ul>
        </Grid>
        <Grid item sm={4} className="redes-sociales">
        <div className="card">
  <Link className="socialContainer containerOne" to="https://api.whatsapp.com/send/?phone=56993273749&type=phone_number&app_absent=0">
  <BsWhatsapp />
  </Link>

<Link className="socialContainer containerTwo" to="https://www.instagram.com/g3propiedadesosorno/"> 
<GrInstagram />
</Link>

  <Link className="socialContainer containerThree" to="https://www.facebook.com/profile.php?id=100094015707106"> <FaFacebookF /></Link>
 
</div>   

        </Grid>
      </Grid>
        )}
    </section>
  );
};
