import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'



import "../../style/home/home.css";

import { Grid } from "@mui/material";

/* imagenes */
import portada from "../../img/home/portada.png";



export const Portadacontacto = () => {
  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 2000,
    });
  }, []);

  return (
    <>
      <section >
        <Grid container>
        <div className="portada-home-img">
        <div className="imagen-portada">
                                        <img
                                            src={portada}
                                            className="imagen-portada-home"
                                            alt="G3 propiedades"// Usamos el ID para describir la imagen
                                        />
        
                                    </div>
                                        <div className="fondo"></div>
                                   
                                    <section className="titulo-portada-home" >
                                        <div className="titulo-principal">
                                            <h1> CONTÁCTANOS</h1>
                                        </div>
                                        <div className="titulo-contacto">
                                        <a  style={{ textDecoration: 'none' }} href="tel:+56993273749"> <h1>+56 9 9327 3749</h1> </a>
                                        </div>
                                    </section>
                                </div>
                        
                    



         
        </Grid>
      </section>
    </>
  );
};
