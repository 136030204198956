import React from "react";
import { Grid } from "@mui/material";
import "../../style/home/servicios.css";

/* imagenes */
import imagen from "../../img/home/servicios/1.png";
import imagen1 from "../../img/home/servicios/2.png";

export const Servicio = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <div className="contiene-todoimagen-servicio">
            <div className="imagen-servicio">
              <img src={imagen} alt="servicios g3propiedades" />
              <div className="lineas"></div>
              <div className="trasparencia"></div>
            </div>
            <div className="titulo">
              <div>
                <h1>NECESITAS VENDER</h1>
                <h2>Descubre el valor de tu propiedad</h2>
              </div>
              <div className="button-servicio">
                <a  style={{ textDecoration: 'none' }} href="/servicios">
                <button className="button-sr">
                  <p className="titulo-button">VER MÁS</p>
                </button></a>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="contiene-todoimagen-servicio">
            <div className="imagen-servicio">
              <img src={imagen1} alt="servicios g3propiedades" />
              <div className="lineas"></div>
              <div className="trasparenciados"></div>
            </div>
            <div className="titulo">
              <div>
                <h1>NECESITAS COMPRAR O</h1>
                <h1>ARRENDAR</h1>
              </div>
              <div className="button-servicio">
                   <a  style={{ textDecoration: 'none' }} href="/servicios">
                <button className="button-sr">
             
                  <p className="titulo-button">VER MÁS</p>
                 
                </button> </a>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
