import React from "react";
import "../../style/footer/footer.css";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

/* imagenes */
import logo from "../../img/navbar/logo.png";

/* iconos */
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsWhatsapp } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";

export const Footer = () => {
  return (
    <section className="footer">
      <section className="footer-arriba">
        <div className="contiene-imagen-logo">
          <img src={logo} alt="G3 propiedades" />
        </div>
        <div className="info-footer">
          <h1> “Tu Aliado en Corretaje y Gestión Inmobiliaria”</h1>
        </div>
        <div className="contacto">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className="telefono">
                <FaPhoneAlt />{" "}
                <a href="tel:+56993273749"> +56 9 9327 3749</a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="mail">
                <HiOutlineMail />
                <a href="mailto:contacto@g3propiedades.cl">
                  contacto@g3propiedades.cl
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="redes-sociales">
          <div class="redes">
            <Link class="socialredesContainer containeruno"  to="https://api.whatsapp.com/send/?phone=56993273749&type=phone_number&app_absent=0">
              <BsWhatsapp />
            </Link>
            <Link class="socialredesContainer  containerdos" to="https://www.instagram.com/g3propiedadesosorno/">
              <GrInstagram />
            </Link>
            <Link class="socialredesContainer  containertres" to="https://www.facebook.com/profile.php?id=100094015707106">
              <FaFacebookF />
            </Link>
          </div>
        </div>
      </section>
      <hr className="separadorfooter"/>
      <section className="bajada-footer">
        <h1><strong>G3 Propiedades 2024</strong></h1>
      </section>
    </section>
  );
};