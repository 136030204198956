import React from "react";
import { Grid } from "@mui/material";
import "../../style/nosotros/nosotros.css"

/* imagenes */

import mision from "../../img/nosotros/mision.png"
import vision from "../../img/nosotros/vision.png"
import uno from "../../img/nosotros/6.png"
import dos from "../../img/nosotros/7.png"
import tres from "../../img/nosotros/8.png"
import cuatro from "../../img/nosotros/9.png"
import cinco from "../../img/nosotros/10.png"



export const Informacionnosotros = () => {
    return(
        <>
        <section className="contiene-nos-info">
        <Grid container>
            <Grid xs={12} sm={6}>
                <div className="titulo-informacion-nosotros">
                    <h1> Tu socio confiable en Gestión Inmobiliaria</h1>
                </div>
            </Grid>
            <Grid xs={12} sm={6}>
                <div className="contiene-descripcion-info-nosotros"><div className="linea-info-g"></div>
                    <div className="informacion-descripcion-nos">
                        <span>Descubre la excelencia en gestión inmobiliaria con G3 Propiedades. Con una sólida trayectoria, hemos logrado numerosas ventas exitosas y contamos con una amplia cartera de propiedades en el sur de Chile. Confía en nosotros para encontrar la propiedad perfecta y superar tus expectativas.</span>
                    </div>
                    
                </div>
            </Grid>
        </Grid>
        <section className="mision-vision">
        <Grid container>
        <Grid xs={12} sm={6}> 
        <div className="contiene-imagen-nosotros">
            <img src={mision} alt="mision"/>
            </div>
            </Grid>
            <Grid xs={12} sm={6}> 
        <div className="contiene-imagen-nosotros">
            <img src={vision} alt="vision"/>
            </div>
            </Grid>
       
        </Grid> 
        </section>

        </section>
        <section className="contiene-valores">
            <section className="titulo-valores">
                <h1> NUESTROS VALORES</h1>
            </section>
            <Grid container className="valores">

                <Grid xs={12} sm={6} md={2}>
                    <div className="valor-info">
                        <img src={uno} alt="valores"  className="imagen-valor" />
                        <div className="info-val">
                            <h1> HONESTIDAD</h1>
                            <span> Realizamos todas las operaciones con transparencia y rectitud </span>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                    <div className="valor-info">
                        <img src={dos} alt="valores" className="imagen-valor" />
                        <div className="info-val">
                            <h1> EQUIDAD</h1>
                            <span> Procedemos con justicia, igualdad e imparcialidad, ante todos nuestros clientes </span>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                    <div className="valor-info">
                        <img src={tres} alt="valores" className="imagen-valor" />
                        <div className="info-val">
                            <h1> RESPONSABILIDAD</h1>
                            <span> Obramos con seriedad, en consecuencia con nuestros deberes y acorde con nuestro compromiso con los clientes </span>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                    <div className="valor-info">
                        <img src={cuatro} alt="valores" className="imagen-valor" />
                        <div className="info-val">
                            <h1> CONFIANZA</h1>
                            <span> Cumplimos con lo prometido al ofrecer los mejores servicios de manera profesional y comprometida </span>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                    <div className="valor-info">
                        <img src={cinco} alt="valores" className="imagen-valor"/>
                        <div className="info-val">
                            <h1> EXCELENCIA</h1>
                            <span> Superamos las expectativas y buscamos perfeccionarnos cada día, convencidos del siempre se puede mejorar </span>
                        </div>
                    </div>
                </Grid>
            </Grid>

        </section>
        
        </>
    )
}