import React from "react";
import { Servicioportada } from "../componentes/servicios/servicioportada";
import { Serviciocontenido } from "../componentes/servicios/serviciocontenido";
export const Servicio = () => {
    return(
        <>
        <Servicioportada/>
        <Serviciocontenido/>
        </>
    )
}