import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { EffectFade, Navigation, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';


import "../../style/home/home.css";

import { Grid } from "@mui/material";

/* imagenes */
import portada from "../../img/home/portada.png";
 import portada2 from "../../img/home/portada2.png" 


export const Portadahome = () => {
  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 2000,
    });
  }, []);
    const Dataportada =[{
        id:"1",
        imagen:portada
    },
    {
        id:"2",
        imagen:portada2
    }


]
  return (
    <>
      <section >
        <Grid container>
        <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        spaceBetween={0}
        effect={'fade'}
        navigation={true}
       
        modules={[EffectFade, Navigation, Autoplay]}
        className="portada-home"
      >
       {Dataportada.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="portada-home-img">
                                    <div className="imagen-portada">
                                        <img
                                            src={item.imagen}
                                            className="imagen-portada-home"
                                            alt={`Portada ${item.id}`} // Usamos el ID para describir la imagen
                                        />
                                        <div className="fondo"></div>
                                    </div>
                                    <section className="titulo-portada-home" >
                                        <div className="titulo-principal">
                                            <h1> DESCUBRE NUESTRAS PROPIEDADES</h1>
                                        </div>
                                        <div className="titulo-contacto">
                                        <a  style={{ textDecoration: 'none' }} href="tel:+56993273749">  <h1> CONTACTANOS </h1> </a><a  style={{ textDecoration: 'none' }} href="tel:+56993273749"><h1>+56 9 9327 3749</h1>  </a>
                                        </div>
                                    </section>
                                </div>
                            </SwiperSlide>
                        ))}


      </Swiper>
         
        </Grid>
      </section>
    </>
  );
};
