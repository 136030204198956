import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";


/* componente */
import { Home } from './pages/home';
import { Navbar } from './componentes/navbar/navbar';
import { Footer } from './componentes/footer/footer';
import { Propiedades } from './pages/propiedades';
import { Servicio } from './pages/servicios';
import { Nosotros } from './pages/nosotros';
import { Contacto } from './pages/contacto';
import { SeleccionPropiedad } from './pages/propeidad-id';


const Layout = () => {

    const basename = process.env.BASENAME || "";

    return (
        <>
       
         <BrowserRouter basename={basename}>
            <Navbar/>
         <Routes> 
         <Route element={<Home />} path="/" />
         <Route element={<Propiedades />} path="/propiedades" />
         <Route element={<Servicio />} path="/servicios" />
         <Route element={<Nosotros/>} path="/nosotros" />
         <Route element={<Contacto/>} path="/contacto" />
         <Route
              path="/propiedad/:id"
              element={<SeleccionPropiedad />}
            /> 
       
         </Routes>
         <Footer/>
         </BrowserRouter>
     
        </>
    )
}


export default (Layout);