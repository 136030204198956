import React from "react";
import { PortadaProp } from "../componentes/propiedad-id/portada-prop";
import { Tituloprop } from "../componentes/propiedad-id/titulo-prop";
import { Caracteristicasprop } from "../componentes/propiedad-id/caracteristicas";
export const SeleccionPropiedad = () =>{
    return(
        <>
       <PortadaProp/>
       <Tituloprop/>
       <Caracteristicasprop/>
       
       </>
    )
}