import React from 'react';

import "../../style/home/home.css";

import { Grid } from "@mui/material";

/* imagenes */
import portada from "../../img/home/portada.png";



export const Portadapropiedades = () => {
  
  return (
    <>
      <section>
        <Grid container>
       
                                <div className="portada-home-img">
                                    <div className="imagen-portada">
                                        <img
                                            src={portada}
                                            className="imagen-portada-home"
                                            alt="G3 propiedades"// Usamos el ID para describir la imagen
                                        />
                                        <div className="fondo"></div>
                                    </div>
                                    <div className="titulo-portada-home">
                                        <div className="titulo-contacto">
                                            <h1> CONTACTANOS </h1> <h1>+56 9 9327 3749</h1>
                                        </div>
                                    </div>
                                    </div>
                        

   
         
        </Grid>
      </section>
    </>
  );
};
