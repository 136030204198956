import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import "../../style/home/categoria.css"
import { Grid } from "@mui/material";

/* imagenes */
import img from "../../img/home/categoria/casas.png"
import img1 from "../../img/home/categoria/departamentos.png"
import img2 from "../../img/home/categoria/parcelas.png"
import img3 from "../../img/home/categoria/campos.png"
import img4 from "../../img/home/categoria/comercio.png"
import img5 from "../../img/home/categoria/sitioindustrial.png"


export const Categoria = () =>{
    useEffect(() => {
        AOS.init({
          // Configura las opciones aquí
          duration: 2000,
        });
      }, []);
    const Data =[
        {
            id:"1",
            titulo:"casas",
            imagen:img
        },
        {
            id:"2",
            titulo:"departamentos",
            imagen:img1
        },
        {
            id:"3",
            titulo:"parcelas",
            imagen:img2
        },
        {
            id:"4",
            titulo:"campos",
            imagen:img3
        },
        {
            id:"5",
            titulo:"PROPIEDADES COMERCIALES",
            imagen:img4
        },
        {
            id:"6",
            titulo:"sitios industriales",
            imagen:img5
        },
    ]
    return(
        <>
        <section data-aos="fade-up">
        <section className="titulo-propiedad">
        <h1>ENCUENTRA TU PROPIEDAD </h1>
        <h1>SOÑADA</h1>
        <hr />
      </section>
      <section className="categorias-home"> 
      <Grid container>
    {Data.map((categoria) => (
        <Grid key={categoria.id} item xs={12} sm={6} md={4} className="categoriafinal">
            <div className="contiene-categoria">
                <div className="contiene-imagencateogria">
                    <img src={categoria.imagen} alt={categoria.titulo} />
                    <div className="superposicion"></div>
                </div>
                <div className="contiene-tituloimagencateogria">
                    <h1>{categoria.titulo}</h1>
                </div>
            </div>
        </Grid>
    ))}
</Grid>
      </section>
       
        </section></>
    )
}