import React from "react";
import "../../style/propiedades/propiedad.css";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Link } from "react-router-dom";

/* iconos */
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsWhatsapp } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";

export const Formulariopropiedades = () => {
  return (
    <section className="section-con-fondo">
      <div className="marco"></div>
      <section className="formulario-contacto-propiedades">
        <Grid container>
          <Grid xs={12} sm={6}>
            <div className="contiene-todo-form">
            <div >
              <section className="informacion-form-prop">
                <h1>CONTACTANOS</h1>
                <h3>
                  {" "}
                  Explora nuevas direcciones, encuentra tu espacio perfecto. Haz
                  clic, contáctanos y comienza tu viaje hacia el hogar ideal.
                </h3>
              </section>
            </div>
            <div>
              <form>
                <div className="formulario-input">
                  <TextField
                    className="input-formulario"
                    fullWidth
                    label="Nombre"
                    variant="filled"
                  />
                </div>
                <div className="formulario-input">
                  <TextField
                    className="input-formulario"
                    fullWidth
                    label="Correo"
                    variant="filled"
                  />
                </div>
                <div className="formulario-input">
                  <TextField
                    className="input-formulario"
                    label="Teléfono"
                    variant="filled"
                    type="tel"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+569</InputAdornment>
                      ),
                    }}
                    fullWidth
                    name="telefono"
                  />
                </div>
                <div className="formulario-input">
                  <TextField
                    className="input-formulario"
                    label="Mensaje"
                    variant="filled"
                    name="mensajes"
                    fullWidth
                  />
                </div>

                <div>
                  {" "}
                  <button className="enviar-form"> ENVIAR</button>
                </div>
              </form>
            </div></div>
          </Grid>
          <Grid xs={12} sm={6} className="contiene-contacto-prop-form">
            <div className="informacion-contacto-general">
              <div className="contacto">
                <div className="contiene-contacto">
                  <FaPhoneAlt /> <a href="tel:+56993273749"> +56 9 9327 3749</a>
                </div>

                <div className="contiene-contacto">
                  <HiOutlineMail />
                  <a href="mailto:contacto@g3propiedades.cl">
                    contacto@g3propiedades.cl
                  </a>
                </div>
              </div>
              <div className="redessociales">
                <div class="redessociales">
                  <Link className="socialredes containeruno"  to="https://api.whatsapp.com/send/?phone=56993273749&type=phone_number&app_absent=0">
                    <BsWhatsapp />
                  </Link>
                  <Link className="socialredes  containerdos" to="https://www.instagram.com/g3propiedadesosorno/">
                    <GrInstagram />
                  </Link>
                  <Link className="socialredes  containertres" to="https://www.facebook.com/profile.php?id=100094015707106">
                    <FaFacebookF />
                  </Link>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </section>
  );
};
