import React from "react";
import { Nosotrosportada } from "../componentes/nosotros/portada-nosotrs";
import { Informacionnosotros } from "../componentes/nosotros/informacion-nosotros";

export const Nosotros =()=>
{
    return(
        <>
      <Nosotrosportada/>
      <Informacionnosotros/>
      </>
    )
}