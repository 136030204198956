import React, { useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import "../../style/propiedades/propiedad.css";

/* menu responsivo */
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';


/* imasgenes */
import img from "../../img/propiedades/arriendo.png";
import img2 from "../../img/propiedades/propiedad.png";
import img3 from "../../img/propiedades/ubicacion.png";
import imagen from "../../img/home/propiedades/propiedad1.png";
import imagen1 from "../../img/home/propiedades/propiedad2.png";
import imagen2 from "../../img/home/propiedades/propiedad3.png";






export const Filtrar = () => {
  const [age, setAge] = React.useState("");
  const [propertiesToShow, setPropertiesToShow] = useState(3); // Inicia mostrando 3 propiedades
  const isLargeScreen = useMediaQuery("(min-width:700px)");

  const handleShowMoreProperties = () => {
    setPropertiesToShow(propertiesToShow + 3);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const data =[
    
    {
        id:"1",
        imagen:imagen,
        dormitorio:"4",
        baño:"2",
        mts2:"120"
    },
    {
        id:"2",
        imagen:imagen1,
        dormitorio:"4",
        baño:"2",
        mts2:"120"
    },
    {
        id:"3",
        imagen:imagen2,
        dormitorio:"4",
        baño:"2",
        mts2:"120"
    },
    {
        id:"4",
        imagen:imagen,
        dormitorio:"4",
        baño:"2",
        mts2:"120"
    },
    {
        id:"5",
        imagen:imagen1,
        dormitorio:"4",
        baño:"2",
        mts2:"120"
    },
    {
        id:"6",
        imagen:imagen2,
        dormitorio:"4",
        baño:"2",
        mts2:"120"
    },
  ]

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Drawer
      anchor={anchor}
      open={state[anchor]}
      onClose={toggleDrawer(anchor, false)}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: '#ffff',
          zIndex:'111111111111111111'
        },
      }}
    >
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, backgroundColor: '#ffff', color: '#ffff' }}
        className="select-filtro-responsivo"
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          <div className="select">
            <div className="form-responsivo">
            <div className="imagen-select">
              <img src={img} alt="g3 propiedades" className="icono-filtrar" />
            </div>
            <div className="select-filtrar">
              <FormControl variant="standard" className="filtracionuno">
                <InputLabel className="input-select-propiedad-filtro" id="demo-simple-select-standard-label">
                  ARRIENDO O VENTA
                </InputLabel>
                <Select
                  className="selecion-filtro"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={age}
                  onChange={handleChange}
                  label="Age"
                >
                  <MenuItem value="">
                    <em>Seleccion</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
              </div>
            </div>
          </div>
          <div className="select">
          <div className="form-responsivo">
              <div className="imagen-select">
                <img src={img3} alt="g3 propiedades" className="icono-filtrar" />{" "}
              </div>
              <div className="select-filtrar">
                {" "}
                <FormControl variant="standard" className="filtraciondos">
                  <InputLabel className="input-select-propiedad-filtro" id="demo-simple-select-standard-label">
                    REGIÓN
                  </InputLabel>
                  <Select
                  className="selecion-filtro"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>Seleccion</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                </div>
              </div>
              <div className="selectfiltrar">
                
                <FormControl variant="standard" className="filtraciondos">
                  <InputLabel className="input-select-propiedad-filtro" id="demo-simple-select-standard-label">
                    CIUDAD
                  </InputLabel>
                  <Select
                  className="selecion-filtro"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>Seleccion</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="select">
            <div className="form-responsivo">
              <div className="imagen-select">
                <img src={img2} alt="g3 propiedades" className="icono-filtrar" />{" "}
              </div>
              <div className="select-filtrar">
                {" "}
                <FormControl variant="standard"  className="filtraciontres" >
                  <InputLabel className="input-select-propiedad-filtro" id="demo-simple-select-standard-label">
                    PROPIEDAD
                  </InputLabel>
                  <Select
                  className="selecion-filtro"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>Seleccion</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                </div>
              </div>
            </div>
            <div className="boton-filtrar">
            <button className="filtrar-boton">LIMPIAR FILTRADO</button>
            </div>
        </List>
      </Box>
    </Drawer>
  );
  return (
    <section >
       
        {isLargeScreen ? (
           <div style={{position:"relative", display:"flex",  justifyContent:"center"}}>
         <section className="filtrar">
        <Grid container>
          <Grid sm={3} className="propiedad-boton-filtrar">
            <div className="select">
              <div className="imagen-select">
                <img src={img} alt="g3 propiedades" className="icono-filtrar" />{" "}
              </div>
              <div className="select-filtrar">
                {" "}
                <FormControl variant="standard" className="filtracionuno">
                  <InputLabel className="input-select-propiedad-filtro" id="demo-simple-select-standard-label">
                    ARRIENDO O VENTA
                  </InputLabel>
                  <Select
                 
                  className="selecion-filtro"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>Seleccion</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
              </div>
              
          </Grid>
          <Grid sm={3} className="propiedad-boton-filtrar">
            <div className="select">
              <div className="imagen-select">
                <img src={img3} alt="g3 propiedades" className="icono-filtrar" />{" "}
              </div>
              <div className="select-filtrar">
                {" "}
                <FormControl variant="standard" className="filtraciondos">
                  <InputLabel className="input-select-propiedad-filtro" id="demo-simple-select-standard-label">
                    REGIÓN
                  </InputLabel>
                  <Select
                  className="selecion-filtro"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>Seleccion</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="select-filtrar">
                {" "}
                <FormControl variant="standard" className="filtraciondos">
                  <InputLabel className="input-select-propiedad-filtro" id="demo-simple-select-standard-label">
                    CIUDAD
                  </InputLabel>
                  <Select
                  className="selecion-filtro"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>Seleccion</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
          <Grid sm={3} className="propiedad-boton-filtrar">
            <div className="select">
              <div className="imagen-select">
                <img src={img2} alt="g3 propiedades" className="icono-filtrar" />{" "}
              </div>
              <div className="select-filtrar">
                {" "}
                <FormControl variant="standard"  className="filtraciontres" >
                  <InputLabel className="input-select-propiedad-filtro" id="demo-simple-select-standard-label">
                    PROPIEDAD
                  </InputLabel>
                  <Select
                  className="selecion-filtro"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>Seleccion</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
          <Grid sm={3} className="propiedadbotonfiltrar">
            <div className="boton-filtrar">
            <button className="filtrar-boton">LIMPIAR FILTRADO</button>
            </div>
          </Grid>
        </Grid>
      </section> </div>
        ) : (
      <section className="filtro-responsivo">
        
        <button title="filter" class="filter" onClick={toggleDrawer("left", true)}>
  <svg viewBox="0 0 512 512" height="1em">
    <path
      d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z"
    ></path>
  </svg>
</button>
<SwipeableDrawer
            anchor="left"
            open={state.left}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            {list("left")}
          </SwipeableDrawer>
      </section>
      )}
      
      
      <section className="propiedades-totales">
      <section className="titulo-propiedad">
        <h1> PROPIEDADES </h1>
      
        <hr />
      </section>
      <section className="prop-completa">
        
        <Grid container>
        {data.slice(0, propertiesToShow).map((property) => (
            <Grid key={property.id}  xs={12} sm={6} md={4}>
              <div className="contiene-todo-homepropiedad">
                <div className="contiene-info-principal">
                  <div className="contiene-tipo">
                    <h1>venta</h1>
                  </div>
                </div>
                <div className="contiene-imagen-propiedad">
                  <img className="imagen-propiedad-home" src={property.imagen} alt="g3 propiedades" />
                </div>
                <div className="contiene-lineas"></div>
                <div className="contiene-fondo"></div>
                <div className="contieneinfoespecifico">
                  <div className="info-propiedad">
                    <h1>{property.dormitorio}</h1>
                    <span>dormitorios</span>
                  </div>
                  <div className="separador"></div>
                  <div className="info-propiedad">
                    <h1>{property.baño}</h1>
                    <span>baños</span>
                  </div>
                  <div className="separador"></div>
                  <div className="info-propiedad">
                    <h1>{property.mts2}</h1>
                    <span>mts2</span>
                  </div>
                </div>
                <div className="superposicionpropiedades">
                  
                <a href='/propiedad/id'>
            <button className="boton-propiedad"> <h1>Ver más</h1></button></a>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        {propertiesToShow < data.length && (
  <section className="boton-mas-propiedades">
    <button className="boton-ver-mas-propiedades" onClick={handleShowMoreProperties}>
      MÁS PROPIEDADES
    </button>
  </section>
)}
      </section>
      </section>
    </ section>
  );
};
